exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-template-tsx-content-file-path-contents-blogs-20210611-initial-commit-index-mdx": () => import("./../../../src/templates/BlogTemplate.tsx?__contentFilePath=/home/runner/work/kaminorse.com/kaminorse.com/contents/blogs/20210611--initial-commit/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-tsx-content-file-path-contents-blogs-20210611-initial-commit-index-mdx" */),
  "component---src-templates-blogs-index-template-tsx": () => import("./../../../src/templates/BlogsIndexTemplate.tsx" /* webpackChunkName: "component---src-templates-blogs-index-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/TagTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

